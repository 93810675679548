import { FunctionsHttpError } from "@supabase/supabase-js";
import type { ExtractResumeTextBody, ExtractResumeTextResponse } from "~/supabase/functions/extract_resume_text/schema";
import type {
  CreateJobOfferResponse,
  DeleteJobOfferResponse,
  GetJobOfferResponse,
  UpdateJobOfferResponse,
} from "~/supabase/functions/ports/job-posting";
import type { CustomErrorSerialized } from "~/supabase/functions/shared/CustomError";
import type { SlackGetMyIdResponse } from "~/supabase/functions/slack_get_my_id/schema";
import type {
  TeamMemberOffboardingBody,
  TeamMemberOffboardingResponse,
} from "~/supabase/functions/team_member_offboarding/schema";
import type { JobPostingHandlerBody } from "~/supabase/functions/use-cases/job-posting-handler/schema";
import type {
  HtmlEmailSenderBody,
  DocumentSenderBody,
  SmsSenderBody,
} from "~/supabase/functions/use-cases/sender-handler/schema";

type SupabaseFunction =
  | {
      name: "team_member_offboarding";
      method: "POST";
      body: TeamMemberOffboardingBody;
      response: TeamMemberOffboardingResponse;
    }
  | {
      name: "slack_get_my_id";
      method: "GET";
      body: undefined;
      response: SlackGetMyIdResponse;
    }
  | {
      name: "talentplug";
      method: "POST";
      body: JobPostingHandlerBody;
      response: GetJobOfferResponse | CreateJobOfferResponse | DeleteJobOfferResponse | UpdateJobOfferResponse;
    }
  | {
      name: "extract_resume_text";
      method: "POST";
      body: ExtractResumeTextBody;
      response: ExtractResumeTextResponse;
    }
  | {
      name: "send_document";
      method: "POST";
      body: DocumentSenderBody;
      response: string;
    }
  | {
      name: "send_html_email";
      method: "POST";
      body: HtmlEmailSenderBody;
      response: string;
    }
  | {
      name: "send_sms";
      method: "POST";
      body: SmsSenderBody;
      response: string;
    };

type SupabaseFunctionBody<T extends SupabaseFunction["name"]> = Extract<SupabaseFunction, { name: T }>["body"];
type SupabaseFunctionResponse<T extends SupabaseFunction["name"]> = Extract<SupabaseFunction, { name: T }>["response"];
type SupabaseFunctionMethod<T extends SupabaseFunction["name"]> = Extract<SupabaseFunction, { name: T }>["method"];

export type SupabaseFunctionOutput<Name extends SupabaseFunction["name"], Data = SupabaseFunctionResponse<Name>> =
  | {
      success: true;
      data: Data;
    }
  | { success: false; error: CustomErrorSerialized | unknown };

export const useSupabaseFunction = () => {
  const supabase = useSupabase();

  const loading = ref(false);
  const invoke = async <Name extends SupabaseFunction["name"], Data = SupabaseFunctionResponse<Name>>(
    name: Name,
    method: SupabaseFunctionMethod<Name>,
    body: SupabaseFunctionBody<Name>
  ): Promise<SupabaseFunctionOutput<Name, Data>> => {
    try {
      loading.value = true;

      const response = await supabase.functions.invoke<Data>(name, {
        method,
        body,
      });

      if (response.error) throw response.error;
      if (!response.data) throw new Error("No data returned");

      loading.value = false;
      return { success: true, data: response.data };
    } catch (exception) {
      logger.error(exception, exception instanceof FunctionsHttpError ? await exception.context.json() : undefined);

      loading.value = false;
      return { success: false, error: exception };
    }
  };

  return { loading, invoke };
};
